import React, { Suspense } from 'react';

import {
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import { useEnvironmentContext } from "../Contexts/EnvironmentProvider";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Header from "./PodcastHeader";
import Footer from "./Footer";
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const PreviewPage = React.lazy(() => import("../Preview/PreviewPage"));
const ProfilePage = React.lazy(() => import("../Profile/ProfilePage"));
const PodcastHistoryPage = React.lazy(() => import("../Podcasts/PodcastHistory"));
const ChannelsPage = React.lazy(() => import("../Channel/Channels"));
const ChannelViewPage = React.lazy(() => import("../Channel/ChannelView"));

const PlaylistPage = React.lazy(() => import("../Playlist/Playlist"));

const LoginPanel = React.lazy(() => import("../Login/LoginPanel"));

const AboutPage = React.lazy(() => import("../Misc/About"));
const GuidePage = React.lazy(() => import("../Misc/Guide"));
const PrivacyPage = React.lazy(() => import("../Misc/Privacy"));
const TermsPage = React.lazy(() => import("../Misc/Terms"));
const ContactPage = React.lazy(() => import('../Misc/Contact'));
const FaqPage = React.lazy(() => import('../Misc/Faq'));
const MembershipPage = React.lazy(() => import('../Misc/Membership'));
const PaymentPage = React.lazy(() => import('../Misc/Subscription'));

const GuestLanding = React.lazy(() => import('../GuestLanding'));
const AzureSignIn = React.lazy(() => import('../Misc/Azure/SignIn'));
const AzureSignOut = React.lazy(() => import('../Misc/Azure/SignOut'));

export default function PodcastSite() {
  const { token, user } = useAuthContext();

  const { siteVariant } = useEnvironmentContext();

  const t = useTheme();
  const isMobile = useMediaQuery(t.breakpoints.down('md'));
  //  const [backgroundUrl, setBackgroundUrl] = useState('');

  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
      mode: 'dark',
      button: {
        main: 'rgba(180, 180, 180, 1)'
      },
      modal: {
        main: 'rgba(50, 50, 50, 1)'
      },
      theme: {
        main: "#FF01CD",
      }
    }
  });

  const navigateToRoot = () => {
    navigate('/', { replace: true });
  };

  const loggedIn = token !== null;

  const routesWithHeader = () => {
    return (
      <>
        <Route path="/guide" element={<GuidePage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/membership" element={<MembershipPage />} />
        <Route path="/subscription" element={<PaymentPage />} />
        <Route path="/faq" element={<FaqPage />} />

        <Route path="/contact" element={<ContactPage />} />

        {!loggedIn &&
          <Route path="/" element={<GuestLanding pageContext="channels" />} />
        }
        <Route path="/channel/:channelId" element={<ChannelViewPage />} />
        <Route path="/playlist/:playlistId" element={<PlaylistPage />} />

        <Route path="/history" element={loggedIn ? <PodcastHistoryPage /> : <GuestLanding pageContext="podcasts" />} />
      </>
    );
  }

  const routesWithoutHeader = () => {
    return (
      <>
        {loggedIn &&
          <Route path="/" element={<ChannelsPage />} />
        }

        <Route path="/members/:handle" element={<ProfilePage />} />
        <Route path="/profile/:userId" element={<ProfilePage />} />
        <Route path="/podcast-ep/:linkId" element={<PreviewPage />} />
        
        <Route path="/auth/ms/login" element={<AzureSignIn />} />
        <Route path="/auth/ms/logout" element={<AzureSignOut />} />
      </>
    );
  }

  const overlayRoutes = () => {
    if (loggedIn) {
      return (
        <>
          <Route path="/login" element={<Navigate to="/" replace={true} />} />,
          <Route path="/register" element={<Navigate to="/" replace={true} />} />,
          <Route path="/resetpassword" element={<Navigate to="/" replace={true} />} />
        </>
      );
    }
    return (
      <>
        <Route path="/login" element={<LoginPanel initialState='login' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />,
        <Route path="/register" element={<LoginPanel initialState='register' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />,
        <Route path="/resetpassword" element={<LoginPanel initialState='resetPassword' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />
      </>
    );
  }

  if (true) {
    return (
      <ThemeProvider theme={theme}>
        <div
          style={{
            position: 'absolute', top: '0px', left: '0px', right: '0px',
            minHeight: '100dvh',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          }}>
          <Suspense fallback={<div />}>
            <Routes>
              {routesWithoutHeader()}
              <Route path="*" element={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%'
                    }}>
                    <Header selectedTab='home' onHome={() => { }} onFavorite={() => { }} />

                    <Box sx={{ minHeight: { 'xs': 'calc(100dvh - 60px - 30px)', 'md': 'calc(100dvh - 60px - 35px)' } }}>
                      <Suspense fallback={<div />}>
                        <Routes>
                          {routesWithHeader()}
                        </Routes>
                      </Suspense>
                    </Box>
                    <Footer />
                  </Box>

                  <Suspense fallback={<div />}>
                    <Routes>
                      {overlayRoutes()}
                    </Routes>
                  </Suspense>
                </>
              } />
            </Routes>
          </Suspense>

        </div>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: 'absolute', top: '0px', left: '0px', right: '0px',
          minHeight: '100dvh',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Box sx={{
          width: { 'xs': '100%', 'md': '60%' },
          position: 'relative',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          display: 'flex'
        }}>
          <Suspense fallback={<div />}>
            <Routes>
              {routesWithoutHeader()}

              <Route path="*" element={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%'
                    }}>
                    <Header selectedTab='home' onHome={() => { }} onFavorite={() => { }} />

                    <Box sx={{ minHeight: { 'xs': 'calc(100% - 50px - 30px)', 'md': 'calc(100% - 90px - 30px)' } }}>
                      <Suspense fallback={<div />}>
                        <Routes>
                          {routesWithHeader()}
                        </Routes>
                      </Suspense>
                    </Box>
                    <Footer />
                  </Box>

                  <Suspense fallback={<div />}>
                    <Routes>
                      {overlayRoutes()}
                    </Routes>
                  </Suspense>
                </>
              } />
            </Routes>
          </Suspense>
        </Box>
      </div>
    </ThemeProvider>
  );
}